textarea {
    resize: none;
}

.login-block {
    width: 50%;
    margin: 0 auto;
    background: #ccc;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
}

.login-holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
}

.login-block .login-text h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 40px;
    text-decoration: underline;
}

.customstyling .css-1okebmr-indicatorSeparator {
    display: none;
}


/* Your CSS */

.table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #DDD;
}

.table-responsive {
    border: 0 none;
}

.table-min-width {
    min-width: 100rem;
}

.overflow {
    overflow: hidden;
}

.custom-table-holder .card {
    overflow: hidden;
}

.table-btn-holder .btn-info {
    margin-left: 8px;
}

.table-btn-holder .btn-info:first-child {
    margin: 0;
}

.custom-table-holder .table-responsive {
    overflow-y: auto;
}

.table-min-width .btn-fill {
    font-size: 20px;
    padding: 5px 15px;
}

.DaySelect .form-control {
    max-width: 160px;
    min-width: 160px;
}

.DaySelectSeprate .form-control {
    max-width: 100px;
    min-width: 100px;
}

.WeekendSelectSeprate .form-control {
    max-width: 200px;
    min-width: 200px;
}

.ClientSelectSeprate .form-control {
    max-width: 150px;
    min-width: 100px;
}

.EmployeeSelectSeprate .form-control {
    max-width: 250px;
    min-width: 100px;
}

.btn-holder {
    display: table-cell;
    text-align: end;
}

.btn-holder .btn-info,
.btn-holder .btn-warning,
.btn-holder .btn-clear,
.table-btn-holder .btn-danger {
    margin-left: 8px;
}

.btn-holder .btn-info:first-child {
    margin: 0;
}


/* .table-block {
    width: 11%;
    margin: 0 15px;
    text-align: center;
} */

.table-block {
    width: 11%;
    max-width: 11%;
    margin: 0 15px;
    display: flex;
    text-align: center;
    flex-grow: 1;
}


/* .daily-hours-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    flex-grow: 1;
    max-width: 15%;
} */

.table-block-weekend {
    margin: 0 15px;
    text-align: center;
}

.table-holder {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.table-block .DaySelectSeprate .form-control {
    width: 100%;
    max-width: 200px;
}

.table-block .WeekendSelectSeprate .form-control {
    width: 100%;
    max-width: 100%;
}

.mx-auto {
    margin: 0 auto;
}

.custom-table-holder .footer {
    display: none;
}

.custom-table-holder .card .content {
    padding: 15px 15px 0px 15px;
}

.navbar-default .navbar-brand {
    font-weight: 300;
    color: #333;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-10 {
    margin-top: 10px;
}

.table-min-width .btn-fill.default-copybtn {
    font-size: 18px;
}

.table-actionbtn .btn {
    font-size: 18px;
    padding: 5px 0;
    display: flex;
    justify-content: space-evenly;
    width: 47%;
    align-items: center;
    float: left;
}

.text-center {
    text-align: center;
}

.custom-table-holder table thead tr th,
.daily-hours-table .rdt_TableHead .rdt_TableCol {
    font-weight: 700;
    color: #333;
}

.daily-hours-table .rdt_TableHead .rdt_TableCol {
    text-transform: uppercase;
    padding-left: 14px;
    padding-right: 14px;
}

.edit-btn {
    background-color: #f46f22;
    color: #fff;
    border-color: #f46f22;
}

.default-btn {
    background-color: #888888;
    color: #fff;
    border-color: #888888;
}

.edit-btn:hover,
.edit-btn:focus,
.edit-btn:active,
.edit-btn:active:hover {
    background-color: #f46f22;
    color: #fff;
    border-color: #f46f22;
}

.ml-2 {
    margin-left: 10px;
}

.mr-2 {
    margin-right: 10px;
}

.w-100 {
    width: 100%;
}

.header-dropdown>.dropdown>a {
    font-size: 20px;
    font-weight: 300;
    color: #333 !important;
}

.InputFromTo .DayPickerInput input {
    border: 1px solid #E3E3E3;
    color: #565656;
    padding: 8px 12px;
    height: 40px;
    box-shadow: none;
    border-radius: 4px;
}

.InputFromTo .DayPickerInput input:focus {
    border-color: #aaa;
}

.DayPickerInput {
    margin: 0 -1px;
}

.search-icon {
    background-color: #595a5c;
    border-color: #595a5c;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    margin-top: 24px;
}

.search-icon:hover,
.search-icon:hover,
.search-icon:active:hover {
    background-color: #595a5c;
    color: #ffffff;
}

.approve-selected,
.approve-selected:hover,
.approve-selected:active:hover {
    background-color: #388d3c;
    color: #ffffff;
    border-color: #388d3c;
}

.rdt_TableCol>div,
.rdt_TableCell>div {
    width: 100%;
}

.approve-actionbtn,
.approve-actionbtn:hover,
.approve-actionbtn:active:hover {
    background-color: #E59866;
    color: #ffffff;
    border-color: #E59866;
}

.mt-24 {
    margin-top: 24px
}


/* clear button colors */

.btn-clear {
    background-color: #8B8B8B;
    border-color: #8B8B8B;
    color: #ffffff;
}

.btn-clear:hover,
.btn-clear:focus,
.btn-clear:active,
.btn-clear.active,
.open>.btn-clear.dropdown-toggle {
    background-color: transparent;
    color: #8B8B8B;
    border-color: #8B8B8B;
}

.btn-clear.active.focus,
.btn-clear.active:focus,
.btn-clear.active:hover,
.btn-clear:active.focus,
.btn-clear:active:focus,
.btn-clear:active:hover,
.open>.dropdown-toggle.btn-clear.focus,
.open>.dropdown-toggle.btn-clear:focus,
.open>.dropdown-toggle.btn-clear:hover {
    color: #fff;
    background-color: #8B8B8B;
    border-color: #8B8B8B;
}

.btn-clear.active,
.btn-clear:active,
.open>.dropdown-toggle.btn-clear {
    color: #fff;
    background-color: #8B8B8B;
    border-color: #8B8B8B;
}

.btn-clear:hover,
.btn-clear:focus,
.btn-clear:active,
.btn-clear.active,
.open>.btn-clear.dropdown-toggle {
    background-color: #8B8B8B;
    color: #ffffff;
    border-color: #8B8B8B;
}


/* success button color  */

.btn-success {
    background-color: #9D833E;
    border-color: #9D833E;
    color: #ffffff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
    background-color: transparent;
    color: #9D833E;
    border-color: #9D833E;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #9D833E;
    border-color: #9D833E;
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #9D833E;
    border-color: #9D833E;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
    background-color: #9D833E;
    color: #ffffff;
    border-color: #9D833E;
}


/* delete button color  */

.btn-delete {
    background-color: #D93D7A;
    border-color: #D93D7A;
    color: #ffffff;
}

.btn-delete:hover,
.btn-delete:focus,
.btn-delete:active,
.btn-delete.active,
.open>.btn-delete.dropdown-toggle {
    background-color: transparent;
    color: #D93D7A;
    border-color: #D93D7A;
}

.btn-delete.active.focus,
.btn-delete.active:focus,
.btn-delete.active:hover,
.btn-delete:active.focus,
.btn-delete:active:focus,
.btn-delete:active:hover,
.open>.dropdown-toggle.btn-delete.focus,
.open>.dropdown-toggle.btn-delete:focus,
.open>.dropdown-toggle.btn-delete:hover {
    color: #fff;
    background-color: #D93D7A;
    border-color: #D93D7A;
}

.btn-delete.active,
.btn-delete:active,
.open>.dropdown-toggle.btn-delete {
    color: #fff;
    background-color: #D93D7A;
    border-color: #D93D7A;
}

.btn-delete:hover,
.btn-delete:focus,
.btn-delete:active,
.btn-delete.active,
.open>.btn-delete.dropdown-toggle {
    background-color: #D93D7A;
    color: #ffffff;
    border-color: #D93D7A;
}


/* duplicate button color  */

.btn-duplicate {
    background-color: #00B0A3;
    border-color: #00B0A3;
    color: #ffffff;
}

.btn-duplicate:hover,
.btn-duplicate:focus,
.btn-duplicate:active,
.btn-duplicate.active,
.open>.btn-duplicate.dropdown-toggle {
    background-color: transparent;
    color: #00B0A3;
    border-color: #00B0A3;
}

.btn-duplicate.active.focus,
.btn-duplicate.active:focus,
.btn-duplicate.active:hover,
.btn-duplicate:active.focus,
.btn-duplicate:active:focus,
.btn-duplicate:active:hover,
.open>.dropdown-toggle.btn-duplicate.focus,
.open>.dropdown-toggle.btn-duplicate:focus,
.open>.dropdown-toggle.btn-duplicate:hover {
    color: #fff;
    background-color: #00B0A3;
    border-color: #00B0A3;
}

.btn-duplicate.active,
.btn-duplicate:active,
.open>.dropdown-toggle.btn-duplicate {
    color: #fff;
    background-color: #00B0A3;
    border-color: #00B0A3;
}

.btn-duplicate:hover,
.btn-duplicate:focus,
.btn-duplicate:active,
.btn-duplicate.active,
.open>.btn-duplicate.dropdown-toggle {
    background-color: #00B0A3;
    color: #ffffff;
    border-color: #00B0A3;
}


/* edit button color  */

.btn-edit {
    background-color: #16ABE0;
    border-color: #16ABE0;
    color: #ffffff;
}

.btn-edit:hover,
.btn-edit:focus,
.btn-edit:active,
.btn-edit.active,
.open>.btn-edit.dropdown-toggle {
    background-color: transparent;
    color: #16ABE0;
    border-color: #16ABE0;
}

.btn-edit.active.focus,
.btn-edit.active:focus,
.btn-edit.active:hover,
.btn-edit:active.focus,
.btn-edit:active:focus,
.btn-edit:active:hover,
.open>.dropdown-toggle.btn-edit.focus,
.open>.dropdown-toggle.btn-edit:focus,
.open>.dropdown-toggle.btn-edit:hover {
    color: #fff;
    background-color: #16ABE0;
    border-color: #16ABE0;
}

.btn-edit.active,
.btn-edit:active,
.open>.dropdown-toggle.btn-edit {
    color: #fff;
    background-color: #16ABE0;
    border-color: #16ABE0;
}

.btn-edit:hover,
.btn-edit:focus,
.btn-edit:active,
.btn-edit.active,
.open>.btn-edit.dropdown-toggle {
    background-color: #16ABE0;
    color: #ffffff;
    border-color: #16ABE0;
}

.text-exact,
.text-exact:hover {
    color: #00B0A3;
}

.text-above,
.text-above:hover {
    color: #16ABE0;
}

.text-below,
.text-below:hover {
    color: #D93D7A;
}

.someElement {
    left: 0;
    line-height: 100px;
    margin-top: 20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
}

.react-autosuggest__container {
    position: relative;
}


/* .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 8px 12px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
} */

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 39px;
    width: 339px;
    border: 1px solid #AAAAAA;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    border-radius: 4px;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.inputContainer {
    position: relative;
}

.mg-tp-25 {
    margin-top: 25px;
}

.radio-new-line label {
    display: block;
}

.btn:disabled,
.btn[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success[disabled],
.btn-success[disabled]:hover {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}


/* .card .category {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0px;
} */

.radio-label {
    padding-right: 10px;
}

.hours-msg {
    color: #00B0A3 !important
}